import { Avatar, Box, ListItem, ListItemText, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { AiOutlineUser } from "react-icons/ai";
import { useHistory, useLocation } from 'react-router-dom';
import { ClientDocumentsFormMikra } from '../components';
import Layout from '../layout';
import NotFound from './404';

const ClientDocumentsMikra = () => {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  let client;
  if (location.state) client = location.state.client;
  else return <NotFound />;

  return (
    <Layout
      topBarProps={{
        backLink: { pathname: '/client', state: { client } },
        text: 'Dokumenti',
      }}
    >
      {
        <Box>
          <Box height="8px" />
          <ListItemStyled>
            <AvatarOut>
              <AvatarIn>{<AiOutlineUser size={22} color={theme.palette.white} />}</AvatarIn>
            </AvatarOut>
            <ListItemText
              primary={`${client.ime} (${client.otac}) ${client.prezime}`}
              secondary={`${client.komitent}`}
              secondaryTypographyProps={{ variant: 'caption' }}
            />
            <Box pr={4} sx={{ color: `${theme.palette.primary.main}70` }}>
            </Box>
          </ListItemStyled>
          <ClientDocumentsFormMikra client={client} history={history} />
        </Box>
      }
    </Layout>
  );
};

const ListItemStyled = styled(ListItem)`
  ${({ theme }) => `
  background-color: ${theme.palette.background.secondary};
  margin-bottom: 30px;
  padding-left: 20px;
  padding-bottom:16px;
  padding-top:16px;
  border-radius: 10px;
  `}
`;
const AvatarIn = styled(Avatar)`
  ${({ theme }) => `
  background-color: ${theme.palette.primary.main};
  height: 36px;
  width: 36px;
  `}
`;
const AvatarOut = styled(Avatar)`
  ${({ theme }) => `
  border: 2px solid ${theme.palette.primary.main};
  height: '40px';
  width: '40px';
  background-color: transparent;
  margin-right:16px;
  `}
`;

export default ClientDocumentsMikra;