import selectClient from '../../hooks/selectClient';
import liderPalette from '../liderPalette';
import mikraPalette from '../mikraPalette';
import partnerPalette from '../partnerPalette';

let palette;
if (selectClient() === 'Partner' || selectClient() === 'PartnerMKD') {
  palette = partnerPalette;
} else if (selectClient() === 'Mikra') {
  palette = mikraPalette;
} else if (selectClient() === 'Lider') {
  palette = liderPalette;
}

const MuiFormControlLabel = {
  styleOverrides: {
    label: {
      color: palette.text.secondary,
    },
  },
};

export default MuiFormControlLabel;
