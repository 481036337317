import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import selectClient from '../hooks/selectClient';
import NotFound from './404';
import LoanApplicationLider from './LoanApplicationLider';
import LoanApplicationMikra from './LoanApplicationMikra';
import LoanApplicationPartner from './LoanApplicationPartner';

const LoanAppParent = () => {
  let application;
  let client;
  const location = useLocation();
  const history = useHistory();
  if (location.state) {
    application = location.state.application;
    client = location.state.client;
  } else {
    history.push('/loanApplications');
    return null;
  }
  if (!application) application = { brojzahtjeva: -1 };
  if (!client) client = { IdKomitent: application.komitent, komitent: application.komitent };
  if(selectClient() === 'Partner' || selectClient() === 'PartnerMKD') {
    return  <LoanApplicationPartner brojZahtjeva={application.brojzahtjeva} defaultClient={client} />
  }
  else if (selectClient() === 'Mikra') {
    return <LoanApplicationMikra brojZahtjeva={application.brojzahtjeva} defaultClient={client} />
  }
  else if (selectClient() === 'Lider') {
    return <LoanApplicationLider brojZahtjeva={application.brojzahtjeva} defaultClient={client} />
  }
 
};
export default LoanAppParent;
