import { Form, Input, Select } from '@components/rhf/src/MUI5';
import { Box, FormControl, InputLabel, MenuItem, Select as MuiSelect, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import {
  addLoanApplication,
  deleteIbsPustanjaKreditaAnalitika,
  editLoanApplication,
  getAllRequestReplacementLoans,
  getApplication,
  getDjelatnostiKreditaHRH,
  getIbsPustanjaKreditaAnalitika,
  insertIbsPustanjaKreditaAnalitika,
} from '../api';
import {
  ActionsWrapper,
  ClientSearchBar,
  InputSlider,
  MatrixBadgePartner,
  ServerError,
  Spinner,
  Stepper,
} from '../components';
import selectClient from '../hooks/selectClient';
import useOfficerProfile from '../hooks/useOfficerProfile';
import { getFirstLine } from '../utils';

const _ = require('lodash');

const LoanFormPartner = ({ registry, IdKomitent, next, brojZahtjeva, setBrZahtjeva, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { officerSifra: idSluzbenik, officerUsername: usernameSluzbenik } = useOfficerProfile();

  const [preostaleOpcije, setPreostaleOpcije] = useState(true);
  const [kamata, setKamata] = useState(0);
  const [rata, setRata] = useState(0);
  const [supplier, setSupplier] = useState(null);
  const [replacementLoans, setReplacementLoans] = useState([]);
  const [application, setApplication] = useState({
    brojzahtjeva: -1,
    amount: 1000,
    gracePeriod: 0,
    repaymentPeriod: 10,
    loanProductType: '',
    loanProducts: '',
    remark: '',
    loanProduct: '',
    level2: '',
    level3: '',
    level4: '',
    level5: '',
    level6: '',
    level7: '',
    recommendedLoanPurpose: '',
    loanType: '',
    payoutType: '',
  });

  const methods = useForm({
    defaultValues: application,
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;

  const vrstaIsplateWatch = useWatch({
    control: methods.control,
    name: 'payoutType',
  });
  const vrstaKreditaWatch = useWatch({
    control: methods.control,
    name: 'loanType',
  });
  const vrstaKreditnogProizvodaWatch = useWatch({
    control: methods.control,
    name: 'loanProductType',
  });
  const level2Watch = useWatch({
    control: methods.control,
    name: 'level2',
  });
  const level3Watch = useWatch({
    control: methods.control,
    name: 'level3',
  });
  const level4Watch = useWatch({
    control: methods.control,
    name: 'level4',
  });
  const level5Watch = useWatch({
    control: methods.control,
    name: 'level5',
  });
  const level6Watch = useWatch({
    control: methods.control,
    name: 'level6',
  });

  const loanAppQuery = useQuery(['loanApplication', brojZahtjeva], () => getApplication(brojZahtjeva), {
    enabled: brojZahtjeva != -1,
    onSuccess: (res) => {
      if (!application.komitent) {
        _.each(res, (val, key) => {
          if (val == null) _.set(res, key, '');
        });
        methods.reset(res);
      }
      setApplication(res);
      setSupplier(res.dobavljac);
    },
  });

  const replacementQuery = useQuery(
    ['replacementLoans', brojZahtjeva],
    () => getIbsPustanjaKreditaAnalitika(brojZahtjeva),
    {
      enabled: brojZahtjeva != -1,
      onSuccess: (res) => {
        let tempLoans = [];
        for (let i = 0; i < res.length; i++) {
          tempLoans.push(res[i].brojUgovora);
        }
        setReplacementLoans(tempLoans);
      },
    }
  );

  const level2 = useQuery(
    ['level2', vrstaKreditnogProizvodaWatch],
    () => getDjelatnostiKreditaHRH(2, vrstaKreditnogProizvodaWatch),
    { enabled: !!vrstaKreditnogProizvodaWatch }
  );
  const level3 = useQuery(['level3', level2Watch], () => getDjelatnostiKreditaHRH(3, level2Watch), {
    enabled: !!level2Watch,
  });
  const level4 = useQuery(['level4', level3Watch], () => getDjelatnostiKreditaHRH(4, level3Watch), {
    enabled: !!level3Watch,
  });
  const level5 = useQuery(['level5', level4Watch], () => getDjelatnostiKreditaHRH(5, level4Watch), {
    enabled: !!level4Watch,
  });
  const level6 = useQuery(['level6', level5Watch], () => getDjelatnostiKreditaHRH(6, level5Watch), {
    enabled: !!level5Watch,
  });
  const level7 = useQuery(['level7', level6Watch], () => getDjelatnostiKreditaHRH(7, level6Watch), {
    enabled: !!level6Watch,
  });

  const loans = useQuery(['krediti', IdKomitent], () => getAllRequestReplacementLoans(IdKomitent, null));

  const { mutateAsync: mutateAddIbsPustanjaKreditaAnalitika } = useMutation(insertIbsPustanjaKreditaAnalitika, {
    onError: (error) => {
      enqueueSnackbar('Greška prilikom unosa zamjenskog kredita', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const { mutateAsync: mutateDeleteIbsPustanjaKreditaAnalitika } = useMutation(deleteIbsPustanjaKreditaAnalitika, {
    onError: (error) => {
      enqueueSnackbar('Greška prilikom brisanja zamjenskog kredita', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const { mutateAsync: mutateAddApplication } = useMutation(addLoanApplication, {
    onSuccess: async (a) => {
      //stepsQuery.data[0] = true;
      let newLoans = [];
      for (let i = 0; i < replacementLoans.length; i++) {
        for (let j = 0; j < loans.data.length; j++) {
          if (replacementLoans[i] == loans.data[j].contractNumber) newLoans.push(loans.data[j]);
        }
      }
      for (let i = 0; i < newLoans.length; i++) {
        newLoans[i].brojZahtjeva = a.data;
        newLoans[i].releaseType = 'R';
        newLoans[i].createdBy = usernameSluzbenik;
        await mutateAddIbsPustanjaKreditaAnalitika(newLoans[i]);
      }
      enqueueSnackbar('Uspješno kreiran zahtjev', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setBrZahtjeva(a.data);
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutateAsync: mutateEditApplication } = useMutation(editLoanApplication, {
    onSuccess: async (a) => {
      if (replacementQuery.data) {
        //obrisani zamjenski krediti
        for (let i = 0; i < replacementQuery.data.length; i++) {
          let isDeleted = true;
          for (let j = 0; j < replacementLoans.length; j++) {
            if (replacementQuery.data[i].brojUgovora == replacementLoans[j]) {
              isDeleted = false;
            }
          }
          if (isDeleted) await mutateDeleteIbsPustanjaKreditaAnalitika(replacementQuery.data[i].id);
        }
        //dodani zamjenski krediti
        for (let i = 0; i < replacementLoans.length; i++) {
          let isNew = true;
          let replacementLoan;
          for (let j = 0; j < loans.data.length; j++) {
            if (replacementLoans[i] == loans.data[j].contractNumber) replacementLoan = loans.data[j];
          }
          for (let j = 0; j < replacementQuery.data.length; j++) {
            if (replacementQuery.data[j].brojUgovora == replacementLoans[i]) {
              isNew = false;
            }
          }
          if (isNew) {
            replacementLoan.brojZahtjeva = brojZahtjeva;
            replacementLoan.releaseType = 'R';
            replacementLoan.createdBy = usernameSluzbenik;
            await mutateAddIbsPustanjaKreditaAnalitika(replacementLoan);
          }
        }
      }

      enqueueSnackbar('Uspješno spremljene promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      next();
    },
    onError: (error) => {
      enqueueSnackbar(getFirstLine(error.response.data.error.message), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const replacementLoansChanged = () => {
    if (replacementQuery.data.length != replacementLoans.length) return true;
    for (let i = 0; i < replacementLoans.length; i++) {
      let change = true;
      for (let j = 0; j < replacementQuery.data.length; j++) {
        if (replacementLoans[i] == replacementQuery.data[j].brojUgovora) change = false;
      }
      if (change) return true;
    }
    return false;
  };

  const replacementLoansChange = (event) => {
    const {
      target: { value },
    } = event;
    setReplacementLoans(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSubmit = (values) => {
    if (!preostaleOpcije) {
      enqueueSnackbar('Podaci o zahtjevu nisu ispravni.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    if (loans.data && vrstaKreditaWatch == 2 && (replacementLoans.length > 2 || replacementLoans.length == 0)) {
      enqueueSnackbar('Izaberite jedan ili dva zamjenska kredita.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    values.komitent = IdKomitent;
    values.brojzahtjeva = brojZahtjeva;
    values.idSluzbenik = idSluzbenik;
    values.usernameSluzbenik = usernameSluzbenik;
    //validacija dobavljaca
    if (vrstaIsplateWatch == 2) {
      values.dobavljac = supplier;
    }

    if (values.payoutType == '2' && values.dobavljac == null) {
      enqueueSnackbar('Molimo unesite dobavljača!', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (application.brojzahtjeva == -1) {
      return mutateAddApplication(values);
    } else if (!_.isEqual(values, application) || replacementLoansChanged()) {
      return mutateEditApplication(values);
    } else next();
  };

  const listItemClick = (data) => {
    if (data) setSupplier(data.IdKomitent);
    else setSupplier(null);
  };
  const tipKreditnogProizvoda = () => {
    let listaKreditnihProizvoda = registry ? [...registry.loanProducts] : [];
    let proizvod = vrstaKreditnogProizvodaWatch;
    if (proizvod == '') return [];

    for (let i = 0; i < listaKreditnihProizvoda.length; i++) {
      if (listaKreditnihProizvoda[i].vrstaProizvoda != proizvod) {
        listaKreditnihProizvoda.splice(i, 1);
        i--;
      }
    }
    return listaKreditnihProizvoda;
  };
  const errorGroup = loans.error || replacementQuery.error || loanAppQuery.error;
  const loadingGroup = loans.isLoading || replacementQuery.isLoading || loanAppQuery.isLoading;
  const noError =
    !loans.error &&
    !loans.isLoading &&
    !replacementQuery.error &&
    !replacementQuery.isLoading &&
    !loanAppQuery.error &&
    !loanAppQuery.isLoading;

  return (
    <Box>
      {errorGroup && <ServerError error={errorGroup} />}
      {loadingGroup && <Spinner />}
      {noError && (
        <Form
          spacing={4}
          methods={methods}
          onSubmit={disabled ? next : handleSubmit}
          inputProps={{ disabled: disabled }}
        >
          <Select
            label="Vrsta kreditnog proizvoda"
            name="loanProductType"
            options={registry ? registry.loanProductType : []}
          />

          <Select label="Namjena Nivo 2" name="level2" options={level2.data || []} />
          <Select label="Namjena Nivo 3" name="level3" options={level3.data || []} />
          <Select label="Namjena Nivo 4" name="level4" options={level4.data || []} />
          <Select label="Namjena Nivo 5" name="level5" options={level5.data || []} />
          <Select label="Namjena Nivo 6" name="level6" options={level6.data || []} />
          <Select label="Namjena Nivo 7" name="level7" options={level7.data || []} />
          <Select
            label="Tip kreditnog proizvoda"
            name="loanProducts"
            options={tipKreditnogProizvoda() || (registry ? registry.loanProducts : [])}
          />
          <Select
            label="Djelatnost kredita"
            name="recommendedLoanPurpose"
            options={registry ? registry.loanPurposeActivities : []}
          />
          <InputSlider
            label="Iznos"
            unit="KM"
            name="amount"
            max={selectClient() === 'Partner' ? 10000 : 50000}
            min={selectClient() === 'Partner' ? 500 : 450}
            step={100}
            xs={6}
            sm={6}
            md={6}
            lg={6}
            disabled={disabled}
          />
          <Stepper
            name="repaymentPeriod"
            label="Broj rata"
            unit="mj."
            xs={6}
            sm={6}
            md={6}
            lg={5}
            disabled={disabled}
          />
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="baseline" justifyContent="center">
              <Typography variant="body2" color="primary">
                Predložena rata kredita:&nbsp;
              </Typography>
              <Typography variant="h2" color="primary">
                {rata} KM
              </Typography>
            </Box>
            <Box display="flex" alignItems="baseline" justifyContent="center" mt={2} mb={4}>
              <Typography variant="caption">Kamatna stopa: {kamata}</Typography>
            </Box>
          </Box>
          <Input label="Grace period" name="gracePeriod" md={6} type="number" />
          <Input label="Namjena korištenja" name="loanProduct" />

          <Select label="Vrsta kredita" name="loanType" options={registry ? registry.loanType : []} md={6} />

          {vrstaKreditaWatch == 2 ? (
            <FormControl sx={{ width: '100%' }}>
              <InputLabel>Zamjenski krediti</InputLabel>
              <MuiSelect multiple value={replacementLoans} onChange={replacementLoansChange}>
                {loans.data &&
                  loans.data.map((loan, index) => (
                    <MenuItem key={index} value={loan.contractNumber}>
                      {loan.contractNumber}
                    </MenuItem>
                  ))}
              </MuiSelect>
            </FormControl>
          ) : (
            <></>
          )}

          <Select label="Vrsta isplate" name="payoutType" options={registry ? registry.payoutType : []} md={6} />

          {vrstaIsplateWatch == 2 ? (
            <AutocompleteBox>
              <ClientSearchBar
                clientSelect={listItemClick}
                placeholder="Odaberite dobavljača"
                idKomitent={application.dobavljac}
              />
            </AutocompleteBox>
          ) : (
            <></>
          )}
          <Input label="Zabilješka" name="remark" md={12} />

          <Box height="16px" />
          <ActionsWrapper isSubmitting={isSubmitting} label="Sljedeći korak" />

          <MatrixBadgePartner
            postojiOpcija={setPreostaleOpcije}
            methods={methods}
            setKamata={setKamata}
            setRata={setRata}
          ></MatrixBadgePartner>
        </Form>
      )}
    </Box>
  );
};
const AutocompleteBox = styled(Box)`
  & .MuiFormControl-marginNormal {
    margin-top: 0;
  }
`;
export default LoanFormPartner;
