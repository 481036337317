import React, { useEffect, useState } from 'react';
import { AiFillBank, AiOutlineUser } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, CircularProgress, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CRKMikra } from '../components';
import ClientDetailsMikra from '../components/ClientDetailsMikra';
import Layout from '../layout';
import { scrollToTop } from '../utils';
import NotFound from './404';

const ClientMikra = () => {
  const location = useLocation();
  const theme = useTheme();
  const [loading, setLoading] = useState(true); 
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (location.state) {
      setClient(location.state.client);
      localStorage.setItem('clientData', JSON.stringify(location.state.client));
      setLoading(false); 
    } else {
      let clientLocalStorage = JSON.parse(localStorage.getItem('clientData'));
      if (!!clientLocalStorage) {
        setClient(clientLocalStorage);
        setLoading(false);
      } else {
        setClient(null);
        setLoading(false);
      }
    }
  }, [location.state]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!client) {
    return <NotFound />;
  }

  let avatarColor;
  let AvatarIcon;

  if (client.vrstaKomitenta === 'F' && client.spol === 'M') {
    avatarColor = theme.palette.secondary.main;
    AvatarIcon = AiOutlineUser;
  } else if (client.vrstaKomitenta === 'F') {
    avatarColor = theme.palette.primary.main;
    AvatarIcon = AiOutlineUser;
  } else {
    avatarColor = theme.palette.darkGray;
    AvatarIcon = AiFillBank;
  }

  return (
    <Layout
      topBarProps={{
        backLink: '/',
        forwardLink: { pathname: '/client/edit', state: { client } },
        fileLink: { pathname: '/client/documents', state: { client } },
        text: 'Detalji o komitentu',
      }}
    >
      <Box display="flex" justifyContent="center">
        <AvatarOutline>
          <Box display="flex" justifyContent="center">
            <AvatarIcon size={32} color={avatarColor} />
          </Box>
        </AvatarOutline>
        {(client.delikvent == 'D' || client.delikvent == 1) && <AvatarDelikvent>D</AvatarDelikvent>}
      </Box>
      <ClientDetailsMikra client={client} />
      <Box display="flex" flexDirection="row" justifyContent="center" mb={8}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={{ pathname: '/loanapplication', state: { client } }}
        >
          Zahtjev za kredit
        </Button>
        <Box width={16} />
        <Button
          variant="contained"
          color="secondary"
          onClick={scrollToTop}
          component={Link}
          to={{ pathname: '/survey', state: { client } }}
        >
          Prodajni razgovor
        </Button>
      </Box>
      <CRKMikra user={client} applicationId={null} />
    </Layout>
  );
};

const AvatarDelikvent = styled(Avatar)`
  ${({ theme }) => `
    background-color:${theme.palette.primary.main};
    position:relative;
    float:left;
    margin-left:-30px;
    top:40px;
    height:27px;
    width:27px;
    font-size:1rem;
    `}
`;

const AvatarOutline = styled(Avatar)`
  ${({ theme }) => `
  height: 72px;
  width: 72px;
  background-color:${theme.palette.lightGray};
  `}
`;

export default ClientMikra;
