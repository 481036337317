import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Box, Fab, List, ListItem, Pagination, Tab, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { getAllAnswers, getAllSurveys } from '../api';
import { ClientSearchBar, Spinner } from '../components';
import AnswersListItem from '../components/Survey/AnswersListItem';
import SpecialListItem from '../components/Survey/SpecialListItem';
import SurveyListItem from '../components/Survey/SurveyListItem';
import selectClient from '../hooks/selectClient';
import useOfficerProfile from '../hooks/useOfficerProfile';
import SurveyIcon from '../images/SurveyIcon.svg';
import MainLayout from '../layout/MainLayout';

const PAGE_LIMIT = 10;

const SurveyList = () => {
  const location = useLocation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { keycloak } = useKeycloak();
  const { officerId } = useOfficerProfile();
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('1');
  const [client, setClient] = useState(location.state ? location.state.client : undefined);
  const handleChange = (_, newValue) => setValue(newValue);

  const {
    isLoading: isLoadingSurveys,
    error: errorSurveys,
    data: dataSurveys,
  } = useQuery(['getAllSurveys'], () => getAllSurveys());

  const {
    isLoading: isLoadingAnswers,
    error: errorAnswers,
    data: dataAnswers,
  } = useQuery(
    ['getAllAnswers', page],
    () =>
      getAllAnswers((keycloak.hasRealmRole('MK_ADMIN') && selectClient() === 'Mikra') || 
        (keycloak.hasRealmRole('admin') && (selectClient() === 'Partner' || selectClient() === 'PartnerMKD')), officerId, {
        page,
        limit: PAGE_LIMIT,
      }),
    {
      enabled: !!officerId,
    }
  );

  const { data: answers, totalCount } = dataAnswers || { data: [], totalCount: 0 };

  const loading = isLoadingSurveys || isLoadingAnswers;
  const error = errorSurveys || errorAnswers;

  const selectionSuccessful = (value) => {
    setClient(value);
    enqueueSnackbar('Uspješno ste odabrali komitenta!', {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };

  const selectionUnsuccessful = () => {
    enqueueSnackbar('Niste odabrali komitenta!', {
      variant: 'warning',
      autoHideDuration: 3000,
    });
  };

  return (
    <MainLayout
      topBarProps={{
        backLink: '/',
        text: 'Anketa',
      }}
    >
      {(loading || !officerId) && <Spinner />}
      {!error &&
        !loading &&
        officerId &&
        (dataSurveys?.length === 0 ? (
          <ListContainer p={5}>
            <EmptyList p={5}>
              <Typography variant="h6" gutterBottom>
                Aktivne ankete
              </Typography>
              <Typography variant="body2" gutterBottom>
                Trenutno ne postoji niti jedna kreirana anketa. Kreirajte anketu klikom na dugme ispod.
              </Typography>
            </EmptyList>
          </ListContainer>
        ) : (
          <ListContainer>
            <TabContext value={value}>
              <TabListWhite
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                TabIndicatorProps={{ style: { height: 4, borderRadius: 5 } }}
              >
                <TabStyled
                  label={<Typography variant="subtitle1">Ankete ({dataSurveys?.length})</Typography>}
                  value="1"
                />
                <TabStyled label={<Typography variant="subtitle1">Odgovori ({totalCount})</Typography>} value="2" />
              </TabListWhite>
              <Box pl={10} pr={10} mb={10}>
                <TabPanelRoot value="1" selectedValue={value}>
                  <Box mt={1} mb={-2}>
                    <ClientSearchBar
                      clientSelect={selectionSuccessful}
                      placeholder={!client ? 'Odaberite komitenta' : `Komitent: ${client?.ime} ${client?.prezime}`}
                    />
                  </Box>
                  <List>
                    <Box mt={5} mb={3}>
                      <Typography variant="subtitle1">Najnovija anketa</Typography>
                      <LatestSurveyLink
                        to={{
                          pathname: client ? `/survey/${dataSurveys[dataSurveys?.length - 1]?._id}` : `/survey`,
                          state: { client },
                        }}
                        onClick={!client ? selectionUnsuccessful : null}
                      >
                        <SpecialListItem
                          data={dataSurveys[dataSurveys?.length - 1]}
                          image={SurveyIcon}
                          background="#7B78FE"
                        />
                      </LatestSurveyLink>
                    </Box>
                    <Box mt={8} mb={3}>
                      <Typography variant="subtitle1">Aktivne ankete</Typography>
                    </Box>
                    {dataSurveys?.map((survey, i) => (
                      <ListItem key={i}>
                        <SurveyListItem
                          props={survey}
                          client={client}
                          handler={selectionUnsuccessful}
                          surveyId={survey['_id']}
                        />
                      </ListItem>
                    ))}
                  </List>
                </TabPanelRoot>
                <TabPanelRoot value="2" selectedValue={value}>
                  <Box mt={1} mb={3}>
                    {answers?.length > 0 ? (
                      <List>
                        {answers?.map((answers, i) => (
                          <ListItem
                            key={i}
                            alignItems="flex-start"
                            component={Link}
                            to={{ pathname: `/answers/${answers?._id}`, state: { client, back: 'survey' } }}
                          >
                            <AnswersListItem props={answers} bgcolor="white" avatar={theme.palette.background.survey} />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Box pt={5}>
                        <EmptyList p={5}>
                          <Typography variant="h6" gutterBottom>
                            Lista odgovora
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            Trenutno ne postoji niti jedna popunjena anketa. Nakon popunjavanja ankete spisak odgovora
                            biti će prikazan ovdje.
                          </Typography>
                        </EmptyList>
                      </Box>
                    )}
                    <Box display="flex" justifyContent="center" align="center">
                      <Pagination
                        count={Math.ceil(totalCount / PAGE_LIMIT)}
                        size="large"
                        variant="outlined"
                        color="primary"
                        sx={{ pt: 5 }}
                        page={page}
                        showFirstButton
                        showLastButton
                        onChange={(_, page) => {
                          setPage(page);
                        }}
                      />
                    </Box>
                  </Box>
                </TabPanelRoot>
              </Box>
            </TabContext>
          </ListContainer>
        ))}
      {(keycloak.hasRealmRole('MK_ADMIN') && selectClient() === 'Mikra') 
          || (keycloak.hasRealmRole('admin') && (selectClient() === 'Partner' || selectClient() === 'PartnerMKD')) ? (
        <Add>
          <Fab component={Link} to="/survey/create" size="medium" color="primary">
            <AiOutlinePlus size={40} />
          </Fab>
        </Add>
      ) : (
        <></>
      )}
    </MainLayout>
  );
};

const TabPanel = ({ children, value, selectedValue }) => {
  const isSelected = value === selectedValue;
  return isSelected ? children : null;
};

const ListContainer = styled(Box)`
  ${({ theme }) => `
  min-width: 100%;
  height: 100%;
  margin-left: -6%;
  margin-right: -6%;
  background-color: ${theme.palette.background.survey};
`}
`;

const EmptyList = styled(Box)`
  ${({ theme }) => `
  border-left: 6px solid ${theme.palette.secondary.main};
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  `}
`;

const LatestSurveyLink = styled(Link)`
  text-decoration: none;
`;

const TabListWhite = styled(TabList)`
  background-color: white;
`;

const TabStyled = styled(Tab)`
  border-bottom: 3px solid #d3f3e3;
`;

const Add = styled(Box)`
  position: fixed;
  bottom: 70px;
  right: 20px;
  size: 20;

  @media (min-width: 1100px) {
    right: 30%;
  }
  & .MuiFab-root {
    border-radius: 50% !important;
    color: white;
  }
`;

const TabPanelRoot = styled(TabPanel)`
  padding: 0px;
`;

export default SurveyList;
