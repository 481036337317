import { saveAs } from 'file-saver';
import moment from 'moment';
import columns from '../../excel/answersSpecificColumns';
import { surveyInstance } from '..';

const returnEmptyStringIfNullOrUndefined = (value) => (value === null || value === undefined ? '' : value);

const createAnswersSpecificExcelFile = async (data, setIsCreating, id) => {
  setIsCreating(true);

  const newColumns = [...columns];

  const row = {
    izvjestaj: data?.survey?.name,
    opis: data?.survey?.description,
    klijent:
      returnEmptyStringIfNullOrUndefined(data?.client?.ime) +
      ' ' +
      returnEmptyStringIfNullOrUndefined(data?.client?.prezime),
    radnik:
      returnEmptyStringIfNullOrUndefined(data?.officer?.officerIme) +
      ' ' +
      returnEmptyStringIfNullOrUndefined(data?.officer?.officerPrezime),
    mobilniTelefon: data?.client?.telefon || '/',
    fiskni: data?.client?.telefonFix || '/',
    brojUgovora: data?.loan?.loanInfo?.loanInfo?.[0]?.brojugovora,
    djelatnost: data?.loan?.loanInfo?.loanInfo?.[0]?.djelatnost,
    daniKasnjenja: data?.loan?.loanInfo?.loanInfo?.[0]?.danikasnjenja,
    datumIsplate: moment(data?.loan?.loanInfo?.loanInfo?.[0]?.datumisplate).format('DD-MM-YYYY'),
    telefonMob: `${returnEmptyStringIfNullOrUndefined(
      data?.loan?.loanInfo?.codebtorPhones?.[0]?.telefonmob
    )} ${returnEmptyStringIfNullOrUndefined(data?.loan?.loanInfo?.codebtorPhones?.[0]?.telefonfix)}`,
    survey: data?.answers,
    ...((process.env.REACT_APP_CLIENT == 'Partner' || process.env.REACT_APP_CLIENT == 'PartnerMKD') ? { gpsAddress: data?.location?.gpsAddress } : {}),
  };

  row?.survey.forEach(({ pitanje, odgovor }, i) => {
    row[pitanje] = odgovor;
    newColumns.push({ header: `${i + 1}. ${pitanje}`, key: pitanje });
  });

  await surveyInstance
    .post('/survey/generateExcelSpecificAnswers', { columns: newColumns, row }, { timeout: 0, responseType: 'blob' })
    .then((response) => {
      setIsCreating(false);
      saveAs(response.data, `Odgovori_${row.klijent}_Anketa_${id}.xlsx`);
    });
};

export default createAnswersSpecificExcelFile;
