import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useKeycloak } from '@react-keycloak/web';
import { getOfficerData } from '../api';
import selectClient from './selectClient';

const useOfficerProfile = () => {
  const [officerData, setOfficerData] = useState({
    officerId: null,
    officerSifra: null,
    officerIme: null,
    officerPrezime: null,
    officerUsername: null,
    officerOrganizacija: null,
  });
  const { keycloak } = useKeycloak();
  const officerDataLogin = useQuery(['officerData'], () => getOfficerData(
    keycloak.profile ? keycloak.profile.email.split('@')[0] : keycloak.tokenParsed.preferred_username
  ), {
    enabled: !!keycloak && (selectClient() === 'Partner' || selectClient() === 'PartnerMKD'),
  });

  useEffect(() => {
    let isMounted = true; // Flag to check if the component is mounted

    const getOfficerData = async () => {
      let userProfile = null;
      if (selectClient() !== 'PartnerMKD') {
        userProfile = await keycloak.loadUserProfile();
      }

      let id, sifra, user, org;
      if (officerDataLogin.data) {
        id = officerDataLogin.data.id;
        sifra = officerDataLogin.data.sifra;
        user = userProfile ? userProfile.email.split('@')[0] : keycloak.tokenParsed.preferred_username;
        org = userProfile && userProfile.attributes.officerOrganizacija ? userProfile.attributes.officerOrganizacija[0] : -1;
      } else if (selectClient() === 'Mikra') {
        if (userProfile) {
          id = userProfile.username;
          sifra = userProfile.username;
          user = userProfile.username;
        } else {
          id = sifra = user = org = null;
        }
      } else {
        if (userProfile) {
          id = userProfile.attributes.officerId ? userProfile.attributes.officerId[0] : -1;
          sifra = userProfile.attributes.officerSifra ? userProfile.attributes.officerSifra[0] : -1;
          user = userProfile.email.split('@')[0];
          org = userProfile.attributes.officerOrganizacija ? userProfile.attributes.officerOrganizacija[0] : -1;
        } else {
          id = sifra = user = org = null;
        }
      }

      // Check if the component is still mounted before updating state
      if (isMounted) {
        setOfficerData({
          officerId: id,
          officerSifra: sifra,
          officerIme: userProfile ? userProfile.firstName : null,
          officerPrezime: userProfile ? userProfile.lastName : null,
          officerUsername: user,
          officerOrganizacija: org,
        });
      }
    };

    getOfficerData();

    // Cleanup function to run when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [officerDataLogin.data, keycloak]);

  return officerData;
};

export default useOfficerProfile;
