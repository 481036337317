import selectClient from '../../hooks/selectClient';
import liderPalette from '../liderPalette';
import mikraPalette from '../mikraPalette';
import partnerPalette from '../partnerPalette';

let palette;
if (selectClient() === 'Partner' || selectClient() === 'PartnerMKD') {
  palette = partnerPalette;
} else if (selectClient() === 'Mikra') {
  palette = mikraPalette;
} else if (selectClient() === 'Lider') {
  palette = liderPalette;
}

const MuiInputLabel = {
  styleOverrides: {
    root: {
      color: palette.text.placeholder,
    },
    shrink: {
      color: `${palette.text.placeholder} !important`,
      fontWeight: 400,
    },
    filled: {
      color: palette.text.placeholder,
      fontWeight: 500,
    },
  },
};

export default MuiInputLabel;
