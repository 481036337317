import axios from 'axios';
import keycloak from '../keycloak';

const appendAuthToken = async (config) => {
  const token = keycloak?.token;
  if (token) config.headers.authorization = token;
  return config;
};

const handleError = (error) => {
  return Promise.reject(error);
};

//potrebno dodati autorizaciju za zahtjeve
export const erpAxios = axios.create({
  baseURL: process.env.REACT_APP_BAZA_ENDPOINT || 'http://localhost:5002/api/',
});
erpAxios.interceptors.request.use(appendAuthToken, handleError);

erpAxios.interceptors.request.use((x) => {
  x.meta = x.meta || {};
  x.meta.requestStartedAt = new Date();
  return x;
});

erpAxios.interceptors.response.use(
  (x) => {
    //console.log(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`);
    const time = new Date().getTime() - x.config.meta.requestStartedAt;
    localStorage.setItem('vrijeme', time);
    return x;
  },
  (x) => {
    //console.error(
    //  `Execution error time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`
    // );
    localStorage.setItem('vrijeme', -1);

    throw x;
  }
);

export const surveyInstance = axios.create({
  baseURL: process.env.REACT_APP_SURVEY_ENDPOINT || 'http://localhost:5001/survey-api/',
});

export { default as CRKRequest } from './CRK/CRKRequest';
export { default as fetchCRKRegistry } from './CRK/fetchCRKRegistry';
export { default as getCRK } from './CRK/getCRK';
export { default as getCRKDocuments } from './CRK/getCRKDocuments';
export { default as getCRKDocumentsData } from './CRK/getCRKDocumentsData';
export { default as getOfficerCRK } from './CRK/getOfficerCRK';

export { default as addDokument } from './Dokument/addDokument';
export { default as deleteDokument } from './Dokument/deleteDokument';
export { default as editDokument } from './Dokument/editDokument';
export { default as getApplicationDocuments } from './Dokument/getApplicationDocuments';
export { default as getClientDocuments } from './Dokument/getClientDocuments';
export { default as getTipoviDokumenata } from './Dokument/getTipoviDokumenata';
export { default as getClientTipoviDokumenata } from './Dokument/getClientTipoviDokumenata';

export { default as addKomitent } from './Komitent/addKomitent';
export { default as editKomitent } from './Komitent/editKomitent';
export { default as editKomitentDatumRodjenja } from './Komitent/editKomitentDatumRodjenja';
export { default as fetchKomitent } from './Komitent/fetchKomitent';
export { default as fetchKomitentById } from './Komitent/fetchKomitentById';
export { default as getAllKomitenti } from './Komitent/getAllKomitenti';
export { default as getKomitentById } from './Komitent/getKomitentiById';
export { default as searchKomitenti } from './Komitent/searchKomitenti';

export { default as addLoanApplication } from './LoanApplication/addLoanApplication';
export { default as editLoanApplication } from './LoanApplication/editLoanApplication';
export { default as getApplication } from './LoanApplication/getApplication';
export { default as getApplications } from './LoanApplication/getApplications';
export { default as getStatus } from './LoanApplication/getStatus';
export { default as getUserApplications } from './LoanApplication/getUserApplications';
export { default as statusGenerisanUgovor } from './LoanApplication/statusGenerisanUgovor';
export { default as statusKompletiran } from './LoanApplication/statusKompletiran';
export { default as statusOdbijen } from './LoanApplication/statusOdbijen';
export { default as statusOdobren } from './LoanApplication/statusOdobren';
export { default as statusOdustao } from './LoanApplication/statusOdustao';
export { default as statusStorniran } from './LoanApplication/statusStorniran';
export { default as statusUToku } from './LoanApplication/statusUToku';
export { default as stepsCompleted } from './LoanApplication/stepsCompleted';
export { default as stepsDisabled } from './LoanApplication/stepsDisabled';
export { default as stepsUnlocked } from './LoanApplication/stepsUnlocked';

export { default as addProcjenaRizika } from './ProcjenaRizika/addProcjenaRizika';
export { default as editProcjenaRizika } from './ProcjenaRizika/editProcjenaRizika';
export { default as getProcjenaRizika } from './ProcjenaRizika/getProcjenaRizika';
export { default as getStepenRizika } from './ProcjenaRizika/getStepenRizika';

export { default as addRacuni } from './Racuni/addRacuni';
export { default as editRacuni } from './Racuni/editRacuni';
export { default as getRacuni } from './Racuni/getRacuni';
export { default as getRacuniForSelect } from './Racuni/getRacuniForSelect';

export { default as editSegmentation } from './Segmentation/editSegmentation';
export { default as getSegmentation } from './Segmentation/getSegmentation';
export { default as insertSegmentation } from './Segmentation/insertSegmentation';

export { default as addSuduznik } from './Suduznik/addSuduznik';
export { default as deleteSuduznik } from './Suduznik/deleteSuduznik';
export { default as editSuduznik } from './Suduznik/editSuduznik';
export { default as getSuduznici } from './Suduznik/getSuduznici';
export { default as getUserCollaterals } from './Suduznik/getUserCollaterals';

export { default as addSurvey } from './Survey/addSurvey';
export { default as archiveSurvey } from './Survey/archiveSurvey';
export { default as createAnswersExcelFile } from './Survey/createAnswersExcelFile';
export { default as createAnswersSpecificExcelFile } from './Survey/createAnswersSpecificExcelFile';
export { default as fetchAnswers } from './Survey/fetchAnswers';
export { default as fetchSurvey } from './Survey/fetchSurvey';
export { default as fillSurvey } from './Survey/fillSurvey';
export { default as getAllAnswers } from './Survey/getAllAnswers';
export { default as getAllSurveys } from './Survey/getAllSurveys';
export { default as getClientAnswers } from './Survey/getClientAnswers';
export { default as getLoanInfo } from './Survey/getLoanInfo';
export { default as getSurveyAnalytics } from './Survey/getSurveyAnalytics';
export { default as getUserAnswers } from './Survey/getUserAnswers';
export { default as getUserLoans } from './Survey/getUserLoans';

export { default as deleteAnalysis } from './Analiza/deleteAnalysis';
export { default as editAnalysis } from './Analiza/editAnalysis';
export { default as editAnalysisSuduznik } from './Analiza/editAnalysisSuduznik';
export { default as getAnalysis } from './Analiza/getAnalysis';
export { default as getComitentAnalysis } from './Analiza/getComitentAnalysis';
export { default as insertAnalysis } from './Analiza/insertAnalysis';

export { default as editTroskoviDomacinstva } from './Domacinstvo/editTroskoviDomacinstva';
export { default as getTroskoviDomacinstva } from './Domacinstvo/getTroskoviDomacinstva';
export { default as insertTroskoviDomacinstva } from './Domacinstvo/insertTroskoviDomacinstva';

export { default as editNotifikacija } from './Notifikacije/editNotifikacija';
export { default as getAllNotifikacije } from './Notifikacije/getAllNotifikacije';
export { default as notifUnreadCount } from './Notifikacije/notifUnreadCount';
export { default as notifikacijeCount } from './Notifikacije/notifikacijeCount';
export { default as searchNotifikacije } from './Notifikacije/searchNotifikacije';

export { default as LoanDates } from './LoanDates';
export { default as getMapAnswers } from './Survey/getMapAnswers';
export { default as deleteIbsPustanjaKreditaAnalitika } from './deleteIbsPustanjaKreditaAnalitika';
export { default as deleteReplacementLoan } from './deleteReplacementLoan';
export { default as existingReplacementLoans } from './existingReplacementLoans';
export { default as fetchRegistry } from './fetchRegistry';
export { default as getAllGradovi } from './getAllGradovi';
export { default as getAllRequestReplacementLoans } from './getAllRequestReplacementLoans';
export { default as getBanke } from './getBanke';
export { default as getCosts } from './getCosts';
export { default as getDailyEventsByUser } from './getDailyEventsByUser';
export { default as getDates } from './getDates';
export { default as getDjelatnostiKreditaHRH } from './getDjelatnostiKreditaHRH';
export { default as getIbsPustanjaKreditaAnalitika } from './getIbsPustanjaKreditaAnalitika';
export { default as getIzvorPrihoda } from './getIzvorPrihoda';
export { default as getKomitentSrodstvo } from './getKomitentSrodstvo';
export { default as getKreditnaMatrica } from './getKreditnaMatrica';
export { default as getKreditniSluzbenici } from './getKreditniSluzbenici';
export { default as getLoanByZahtjevId } from './getLoanByZahtjevId';
export { default as getMatrica } from './getMatrica';
export { default as getMjesnaZajednicaByOpcina } from './getMjesneZajednice';
export { default as getOdborGrupe } from './getOdborGrupe';
export { default as getOfficerData } from './getOfficerData';
export { default as getOrganizacijaRadnik } from './getOrganizacijaRadnik';
export { default as getRazlogOdbijanja } from './getRazlogOdbijanja';
export { default as getSegmenti } from './getSegmenti';
export { default as getSrodstva } from './getSrodstva';
export { default as getStatusOdbijenRazlozi } from './getStatusOdbijenRazlozi';
export { default as getStatusiZaposlenja } from './getStatusiZaposlenja';
export { default as getTipDokumenta } from './getTipDokumenta';
export { default as insertCosts } from './insertCosts';
export { default as insertEvent } from './insertEvent';
export { default as insertIbsPustanjaKreditaAnalitika } from './insertIbsPustanjaKreditaAnalitika';
export { default as loanMatrix } from './loanMatrix';
export { default as loanReleaseAnalytics } from './loanReleaseAnalytics';
export { default as replacementLoans } from './replacementLoans';
export { default as setDatumPotpisaUgovora } from './setDatumPotpisaUgovora';
export { default as setGarancija } from './setGarancija';
export { default as setGenerisanUgovorPolja } from './setGenerisanUgovorPolja';
export { default as setKrdOdborGrupa } from './setKrdOdborGrupa';
export { default as setOdobrioKOdbor1 } from './setOdobrioKOdbor1';
export { default as statusOdbijenRazlog } from './statusOdbijenRazlog';
