import { Form } from '@components/rhf/src/MUI5';
import { Box } from '@mui/material';
import Compressor from 'compressorjs';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ActionsWrapper, ServerError, Spinner, Upload } from '.';
import {
  addDokument,
  deleteDokument,
  getClientDocuments,
  getClientTipoviDokumenata,
  insertEvent
} from '../api';
import useOfficerProfile from '../hooks/useOfficerProfile';
import getCoords from '../utils/getCoords';

const ClientDocumentsFormMikra = ({client, history}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { officerUsername } = useOfficerProfile();
  const clientId = client.komitent;

  const methods = useForm({
    defaultValues: {},
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;

  const [files, setFiles] = useState([]);
  const [downloaded, setDownloaded] = useState(false);

  const documents = useQuery(['clientDocuments', clientId], () => getClientDocuments(clientId), {
    onSuccess: (res) => {
      //duboko kopiranje dokumenata
      setFiles(JSON.parse(JSON.stringify(res)));
      setDownloaded(true);
    },
    enabled: !downloaded,
  });
  const docTypes = useQuery('clientTipoviDokumenata', getClientTipoviDokumenata);

  const { mutateAsync: mutateDeleteDocument } = useMutation(deleteDokument, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno spremljene promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar('Greška prilikom brisanja dokumenta', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const { mutateAsync: mutateAddDocument } = useMutation(addDokument, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno ste dodali dokument.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar('Greška prilikom unosa dokumenta', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const newDocument = async (document) => {
    try {
    const formData = new FormData();
    formData.append('name', document.name);
    formData.append('path', document.path);
    formData.append('doctype', document.docType);
    formData.append('size', document.size);

    await new Promise((resolve, reject) => {
      new Compressor(document, {
        quality: 0.8,
        convertSize: 1000000,
        maxWidth: 950,
        maxHeight: 950,
        success(result) {
          formData.append('file', result, document.name);
          resolve();
        },
        error(error) {
          console.error('Compression error:', error);
          formData.append('file', document, document.name);
          resolve();
        },
      });
    });

    formData.append('brojzahtjeva', null);
    formData.append('createdBy', officerUsername);
    formData.append('clientId', client.komitent);
    formData.append('opis', !!document.opis ? document.opis : null);

    await getCoords().then((coords) => {
      insertEvent({
        lat: coords ? coords.latitude : null,
        lng: coords ? coords.longitude : null,
        alt: coords ? coords.altitude : null,
        docId: -1,
        docType: 6, // 6 - DOKUMENT KOMITENT
        emp: officerUsername ? Number(officerUsername) :null,
      });
    });

    return mutateAddDocument(formData);
  } catch (e) {console.log('error---', e)};
  };
  const handleSubmit = async () => {
    //validacija tipova dokumenata
    let tipGreska = false;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].docType == -1) tipGreska = true;
      }
    }
    if (tipGreska) {
      enqueueSnackbar('Izaberite tip dokumenata', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    let promises = [];
    //brisanje starih fajlova koji su obrisani (mutate)
    if (documents.data) {
      for (let i = 0; i < documents.data.length; i++) {
        let notDeleted = false;
        if (files) {
          for (let j = 0; j < files.length; j++) {
            //provjeriti na osnovu kojeg parametra se porede
            if (documents.data[i].id == files[j].id) {
              notDeleted = true;
            }
          }
        }
        if (!notDeleted) promises.push(mutateDeleteDocument(documents.data[i].id));
      }
    }

    //nove dokumente spremi isto kao u loanapplication
    if (files) {
      for (let i = 0; i < files.length; i++) {
        let added = true;
        if (documents.data) {
          for (let j = 0; j < documents.data.length; j++) {
            //provjeriti na osnovu kojeg parametra se porede
            if (files[i].id == documents.data[j].id) {
              added = false;
            }
          }
        }
        if (added) promises.push(await newDocument(files[i]));
      }
    }
    queryClient.invalidateQueries(['clientDocuments', clientId]);
    Promise.all(promises).then(() => history.push('/client'));
    return Promise.all(promises);
  };
  const handleChange = (values) => {
    setFiles(values);
  };

  return (
    <>
      {(documents.error || docTypes.error) && <ServerError error={documents.error || docTypes.error} />}
      {(documents.isLoading || docTypes.isLoading) && <Spinner />}
      {!documents.isLoading && !documents.error && docTypes.data && (
        <Box>
          <Box mb={4} />
          <Form methods={methods} onSubmit={handleSubmit}>
            <Upload handleChange={handleChange} docTypes={docTypes.data} defaultFiles={files} disabled={false} disableDelete={false}/>
            <Box height="16px" />
            <ActionsWrapper isSubmitting={isSubmitting} label="Spremi dokumente" />
          </Form>
        </Box>
      )}
    </>
  );
};

export default ClientDocumentsFormMikra;