import { Form } from '@components/rhf/src/MUI5';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ActionsWrapper, DocumentButton, ServerError, Spinner, Upload } from '.';
import {
  addDokument,
  deleteDokument,
  editDokument,
  getApplicationDocuments,
  getTipoviDokumenata,
  insertEvent,
} from '../api';
import selectClient from '../hooks/selectClient';
import useOfficerProfile from '../hooks/useOfficerProfile';
import documents2 from '../images/documents2.svg';
import documents4 from '../images/documents4.svg';
import getCoords from '../utils/getCoords';

const ApplicationDocumentsPartner = ({ applicationId, previous, next, disabled }) => {
  const CRKBaseLink = (selectClient() === 'Partner') ?
    'https://10.0.0.16:7963/reports/rwservlet?prcld_test_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/krd/crk/krd_crk_komitenta.rdf' :
    'http://10.0.0.16:17269/reports/rwservlet?pakdib_test_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/pakdib/krd/crk/krd_crk_komitenta.rdf'
  const CRKLink = `${CRKBaseLink}&p_brojzahtjeva=${applicationId}`;

  const kontrolaBaseLink = (selectClient() === 'Partner') ?
    'https://10.0.0.16:7963/reports/rwservlet?prcld_test_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/prcld/zahtjevi/kontrola_klijenta.rdf' :
    'http://10.0.0.16:17269/reports/rwservlet?pakdib_test_login&desformat=pdf&destype=cache&server=RptSrv12c&EXPIRATION=30&report=/pakdib/zahtjevi/kontrola_klijenta.rdf';
  const kontrolaLink = `${kontrolaBaseLink}&p_brojzahtjeva=${applicationId}`;

  const queryClient = useQueryClient();

  const methods = useForm({
    defaultValues: {},
    mode: 'onBlur',
  });

  const { isSubmitting } = methods.formState;

  const [files, setFiles] = useState([]);
  const [downloaded, setDownloaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { officerId, officerUsername } = useOfficerProfile();
  const documents = useQuery(['applicationDocuments', applicationId], () => getApplicationDocuments(applicationId), {
    onSuccess: (res) => {
      //duboko kopiranje dokumenata
      setFiles(JSON.parse(JSON.stringify(res)));
      setDownloaded(true);
    },
    enabled: !downloaded,
  });
  const docTypes = useQuery('tipoviDokumenata', getTipoviDokumenata);

  const { mutateAsync: mutateDeleteDocument } = useMutation(deleteDokument, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno spremljene promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar('Greška prilikom brisanja dokumenta', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutateAsync: mutateEditDocument } = useMutation(editDokument, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno spremljene promjene.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar('Greška prilikom uređivanja dokumenta', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const { mutateAsync: mutateAddDocument } = useMutation(addDokument, {
    onSuccess: () => {
      enqueueSnackbar('Uspješno ste dodali dokument.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar('Greška prilikom unosa dokumenta', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
  const newDocument = async (document) => {
    const formData = new FormData();
    formData.append('name', document.name);
    formData.append('path', document.path);
    formData.append('doctype', document.docType);
    formData.append('size', document.size);
    formData.append('file', document, document.name);
    formData.append('brojzahtjeva', applicationId);
    formData.append('createdBy', officerUsername);

    await getCoords().then((coords) => {
      insertEvent({
        lat: coords ? coords.latitude : null,
        lng: coords ? coords.longitude : null,
        alt: coords ? coords.altitude : null,
        docId: applicationId ? parseInt(applicationId) : -1,
        docType: 5, // 5 - DOKUMENT ZAHTJEV
        emp: officerId ? Number(officerId) : -1,
      });
    });

    return mutateAddDocument(formData);
  };
  const handleSubmit = () => {
    //validacija tipova dokumenata
    let tipGreska = false;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].docType == -1) tipGreska = true;
      }
    }
    if (tipGreska) {
      enqueueSnackbar('Izaberite tip dokumenata', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    let promises = [];
    //brisanje starih fajlova koji su obrisani (mutate)
    if (documents.data) {
      for (let i = 0; i < documents.data.length; i++) {
        let notDeleted = false;
        if (files) {
          for (let j = 0; j < files.length; j++) {
            //provjeriti na osnovu kojeg parametra se porede
            if (documents.data[i].id == files[j].id) {
              notDeleted = true;
              //promijeni tip dokumenata kod starih kojima je promijenjen
              if (documents.data[i].docType != files[j].docType) promises.push(mutateEditDocument(files[j]));
            }
          }
        }
        if (!notDeleted) promises.push(mutateDeleteDocument(documents.data[i].id));
      }
    }

    //nove dokumente spremi isto kao u loanapplication
    if (files) {
      for (let i = 0; i < files.length; i++) {
        let added = true;
        if (documents.data) {
          for (let j = 0; j < documents.data.length; j++) {
            //provjeriti na osnovu kojeg parametra se porede
            if (files[i].id == documents.data[j].id) {
              added = false;
            }
          }
        }
        if (added) promises.push(newDocument(files[i]));
      }
    }
    queryClient.invalidateQueries(['applicationDocuments', applicationId]);
    Promise.all(promises).then(() => next());
    return Promise.all(promises);
  };
  const handleChange = (values) => {
    setFiles(values);
  };

  let actionLabel;
  actionLabel = 'Sljedeći korak';

  return (
    <>
      {(documents.error || docTypes.error) && <ServerError error={documents.error || docTypes.error} />}
      {(documents.isLoading || docTypes.isLoading) && <Spinner />}
      {!documents.isLoading && !documents.error && docTypes.data && (
        <Box>
         
  
              <DocumentButton link={CRKLink} name={`CRK-${applicationId}`} label="CRK" icon={documents4} />
              <DocumentButton
                link={kontrolaLink}
                name={`Kontrola-${applicationId}`}
                label="Kontrola"
                icon={documents2}
              />
       
        

          <Box mb={4} />
          <Form methods={methods} onSubmit={disabled ? next : handleSubmit}>
            <Upload handleChange={handleChange} docTypes={docTypes.data} defaultFiles={files} disabled={disabled} />
            <Box height="16px" />
            <ActionsWrapper previous={previous} isSubmitting={isSubmitting} label={actionLabel} />
          </Form>
        </Box>
      )}
    </>
  );
};

export default ApplicationDocumentsPartner;
