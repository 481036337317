import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CacheBusterWrapper from './components/CacheBuster';

ReactDOM.render(
  <React.StrictMode>
    <CacheBusterWrapper>
      <App />
    </CacheBusterWrapper>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

//Rebuild pwa

reportWebVitals();